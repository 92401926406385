import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import { FeatureBlockEntity } from '../types/contentfulModels/FeatureBlockEntity';
import { FeatureBlockRepeaterEntity } from '../types/contentfulModels/FeatureBlockRepeaterEntity';

import IconTypes from '../data/types/IconTypes';

import CalloutBlock from '../components/CalloutBlock/CalloutBlock';
import DesignProcess from '../components/DesignProcess/DesignProcess';

// import ImageGrid, { ImageGridProps } from '../components/ImageGrid/ImageGrid';
import Hero from '../components/Hero/Hero';
import LinkButton from '../components/Buttons/LinkButton/LinkButton';
import ProjectCallout from '../components/ProjectCallout/ProjectCallout';
import TrustBlock from '../components/TrustBlock/TrustBlock';
// import IconArrowRight from '../components/Icons/IconArrowRight';
import Decorator from '../components/Decorator/Decorator';
import Seo from '../components/Seo/Seo';
import CarbonCallout from '../components/CarbonCallout/CarbonCallout';

import FeatureBlockAlternative from '../components/FeatureBlock/FeatureBlockAlternative';

interface DesignCategoriesEntity {
    designCategories: {
        title: string
        description: RenderRichTextData<ContentfulRichTextGatsbyReference>
        categoryFeatures: FeatureBlockEntity[]
    }[],
}

interface DesignPageProps {
    data: {
        contentfulGenericPage: {
            title: string,
            heroImage?: {
                url: string
            }
            // pageBody: Array<FeatureBlockRepeaterEntity|DesignCategoriesEntity|ImageGridProps>
            pageBody: Array<FeatureBlockRepeaterEntity|DesignCategoriesEntity>
        }
    }
}

const DesignPage = ({ data }: DesignPageProps): JSX.Element => {
    const { title: pageTitle } = data.contentfulGenericPage;
    const heroImage = (data.contentfulGenericPage.heroImage) ? `${data.contentfulGenericPage.heroImage.url}?fm=webp&w=1920` : undefined;
    const designProcessData = (data.contentfulGenericPage.pageBody[0] as DesignCategoriesEntity).designCategories;
    const trustBlockFeatures = (data.contentfulGenericPage.pageBody[1] as FeatureBlockRepeaterEntity).featureBlocks;
    // const imageGridData = data.contentfulGenericPage.pageBody[2] as ImageGridProps;
    const designFeatures = (data.contentfulGenericPage.pageBody[3] as FeatureBlockRepeaterEntity).featureBlocks;
    // TODO: Maybe refactor this to use a more bespoke Contentful content type instead of generic repeater

    return (
        <Fragment>
            <Seo title={pageTitle} />
            <div className="space-y-7">
                <Hero
                    height="small"
                    background={heroImage}
                >
                    <Hero.Body containerWidth="medium" padding="small" justifyItems="left">
                        <div className="space-y-1 max-w-0.25">
                            <Hero.Title><span className="gold">Designing down to the finest detail</span></Hero.Title>
                            { /* <Hero.Subtitle>Nesciunt est dicta sit. Aperiam similique dolores et quas ut. Placeat neque explicabo. Tenetur at nesciunt.</Hero.Subtitle> */ }
                            {/* <LinkButton href="/project-planner" label="Start your project" icon={<IconArrowRight size="24px" stroke="slate-900" />} /> */}
                        </div>
                    </Hero.Body>
                    <Hero.Footer containerWidth="medium" justifyItems="left" className="pb-1 offset-bg offset-bg--bottom offset-bg--slate-900">
                        <TrustBlock features={trustBlockFeatures} />
                    </Hero.Footer>
                </Hero>

                <section className="contain containMedium space-y-6">
                    <header className="flex flex-col md:flex-row space-y-1">
                        <div className="w-full md:w-2/3 md:pr-6 space-y-1">
                            <h2 className="text-subtitle">Our design process</h2>
                            <p className="text-paragraph">When working with you either as part of a full build or just the
                                planning phase we follow our same design process.
                            </p>
                            <p className="text-paragraph">This ensures that we are able to deliver the best possible result for you and your project.</p>
                        </div>
                        <div className="w-full md:w-1/3 flex items-center">
                            <LinkButton href="#explore-services" label="See the full service list" bordered />
                        </div>
                    </header>
                    <section className="space-y-3">
                        <DesignProcess>
                            {
                                designProcessData.map((step, index) => {
                                    const stepFeatures = step.categoryFeatures;

                                    return (
                                        <DesignProcess.Step
                                            heading={step.title}
                                            body={step.description}
                                            step={index + 1}
                                            key={step.title}
                                        >
                                            {
                                                stepFeatures.map((feature) => {
                                                    const IconId = (typeof feature.icon === 'object') ? feature.icon.contentfulid : feature.icon;
                                                    const IconComponent = IconTypes[IconId];

                                                    return (
                                                        <div key={`feature-block-${IconId}`}>
                                                            <div className="flex items-center gap-1">
                                                                {(IconComponent) ? <IconComponent size="32px" /> : null}
                                                                {
                                                                    (feature.readMoreTarget)
                                                                        ? (
                                                                            <Link to={`#${feature.readMoreTarget.slug}`} className="text-label text-underline decoration-bronze-500">{feature.heading}</Link>
                                                                        )
                                                                        : <span className="text-label underline">{feature.heading}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </DesignProcess.Step>
                                    );
                                })
                            }
                        </DesignProcess>
                    </section>
                    <div className="contain containMedium">
                        <CalloutBlock
                            heading="Looking for build services?"
                            body="Looking to start a new build project? We can help you with that too."
                            linkTo="/build"
                            linkLabel="See what we can do"
                        />
                    </div>
                </section>
                { /* <section className="contain containMedium"> */ }
                { /*    <ImageGrid */ }
                { /*        layout={imageGridData.layout} */ }
                { /*        images={imageGridData.images} */ }
                { /*    /> */ }
                { /* </section> */ }
                <div className="text-center">
                    <Decorator />
                </div>
                <section id="explore-services">
                    <div className="contain containMedium space-y-6">
                        <h2 className="text-subtitle text-center">Explore services</h2>
                        <div className="fullFeatureGrid">
                            {
                                designFeatures.map((feature) => (
                                    <FeatureBlockAlternative
                                        key={feature.slug}
                                        slug={feature.slug}
                                        icon={feature.icon.contentfulid}
                                        heading={feature.heading}
                                        description={feature.description}
                                        descriptionClassName="grow"
                                    />
                                ))
                            }
                        </div>
                    </div>
                </section>
                <div className="text-center">
                    <Decorator />
                </div>
            </div>
            <ProjectCallout />
            <CarbonCallout />
        </Fragment>
    );
};

export const pageQuery = graphql`
query DesignPageContentQuery {
    contentfulGenericPage(slug: {eq: "designPage"}) {
        title
        heroImage {
            url
        }
        pageBody {
          ... on ContentfulDesignCategoryRepeater {
            id
            designCategories {
              title
              description {
                raw
              }
              categoryFeatures {
                heading
                slug
                icon {
                  name
                  contentfulid
                }
                description {
                    description
                }
                readMoreTarget {
                    slug
                }
              }
            }
          }
          ... on ContentfulTrustBlock {
            title
            featureBlocks {
              heading
              slug
              icon {
                name
                contentfulid
              }
              description {
                description
              }
            }
          }
          ... on ContentfulImageGrid {
            layout
            images {
                id
                description
                gatsbyImageData(
                    width: 1920
                )
              }
          }
          ... on ContentfulFeatureBlockRepeater {
            featureBlocks {
                heading
                slug
                description {
                    description
                }
                icon {
                    contentfulid
                    name
                }
            }
          }
        }
      }
  }
`;

export default DesignPage;
