import React from 'react';

import IconTypes from '../../data/types/IconTypes';

import * as styles from './FeatureBlock.module.scss';

interface FeatureBlockProps {
    icon: {
        contentfulid: string
    } | string,
    heading: string,
    headingClassName?: 'text-label'|'text-subheading'|'text-heading',
    slug: string,
    description: {
        description: string
    } | string,
    descriptionClassName?: 'text-metadata'|'text-paragraph'|'grow',
    spaceY?: 1|2|3,
}

const FeatureBlockAlternative = ({
    icon, heading, headingClassName = 'text-label', description, descriptionClassName = 'text-metadata', slug, spaceY = 1
}: FeatureBlockProps): JSX.Element => {
    const IconId = (typeof icon === 'object') ? icon.contentfulid : icon;
    const IconComponent = IconTypes[IconId];

    return (
        <div id={slug} className={`${styles.FeatureBlock} space-y-${spaceY}`}>
            <div className="flex gap-1 items-start items-center">
                {(IconComponent) ? <IconComponent size="32px" /> : null}
                <span className={`${headingClassName}`}>{heading}</span>
            </div>
            <p className={descriptionClassName}>{(typeof description === 'object') ? description.description : description}</p>
        </div>
    );
};

export default FeatureBlockAlternative;
