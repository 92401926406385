import React from 'react';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import * as styles from './DesignProcess.module.scss';

import Counter from '../Counter/Counter';
import RichTextBlock from '../RichTextBlock/RichTextBlock';

interface DesignProcessStepProps {
    heading: string,
    body: RenderRichTextData<ContentfulRichTextGatsbyReference>,
    children: React.ReactNode,
    step: number
}

const DesignProcessStep = ({
    heading, body, children, step
}: DesignProcessStepProps): JSX.Element => (
    <li className={`${styles.DesignProcessStep}`}>
        <div className={`${styles.DesignProcessStepHeader} ${styles.DesignProcessStepPadded}`}>
            <h2 className="text-subtitle">{heading}</h2>
        </div>
        <div className={`${styles.DesignProcessStepContent} ${styles.DesignProcessStepPadded}`}>
            <RichTextBlock content={body} className={styles.DesignProcessStepBody} />
            <div className={styles.DesignProcessStepFeatures}>
                {children}
            </div>
        </div>
        <Counter label={`0${step}`} className={styles.DesignProcessStepCounter} />
    </li>
);

export default DesignProcessStep;
