import React from 'react';

import DesignProcessStep from './DesignProcessStep';

import * as styles from './DesignProcess.module.scss';

interface DesignProcessProps {
    children: React.ReactNode
}

const DesignProcess = ({ children }: DesignProcessProps): JSX.Element => (
    <ol className={styles.DesignProcess}>
        {children}
    </ol>
);

DesignProcess.Step = DesignProcessStep;

export default DesignProcess;
